<template>
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Body-->
    <div class="card-body">
      <div class="d-flex flex-column justify-content-between h-100">
        <!--begin::User-->
        <div class="d-flex align-items-start mb-7 flex-fill">
          <!--begin::Pic-->
          <div class="d-flex align-items-center">
            <!--begin::Title-->
            <div class="d-flex flex-column">
              <span class="text-dark-75 font-weight-bold font-size-h6 mb-0">{{
                name
              }}</span>
              <span
                v-if="average"
                class="text-muted font-weight-bold font-size-md mb-0 provide-muted-title"
                ><em>{{ averagePrice }}</em></span
              >
            </div>
            <!--end::Title-->
          </div>
          <!--end::Title-->
        </div>
        <!--end::User-->
        <div class="d-flex align-items-center">
          <button
            @click="handleEditButtonClick"
            type="button"
            class="btn flex-fill btn-sm btn-light font-weight-bolder text-uppercase py-4"
          >
            Düzenle
          </button>
          <button
            @click="handleDeleteButtonClick"
            type="button"
            class="btn btn-icon btn-lg ml-2 btn-light font-weight-bolder text-uppercase py-4"
          >
            <i class="flaticon2-trash icon-md"></i>
          </button>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
export default {
  name: "ExpenseItem",
  props: {
    id: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      default: ""
    },
    average: {
      type: Number,
      default: 0
    }
  },
  computed: {
    averagePrice() {
      return `Ortalama: ${this.average} TRY`;
    }
  },
  methods: {
    handleEditButtonClick() {
      this.$emit("editClick", this.id);
    },
    handleDeleteButtonClick() {
      this.$emit("deleteClick", this.id);
    }
  }
};
</script>

<style></style>
